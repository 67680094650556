import React, { Component } from 'react';
import { connect } from 'react-redux';
import { toggleSideNav } from '../redux/actions';

import NavLanguageSelector from './NavLanguageSelector';
import { Link } from 'react-router-dom';
import { translations} from "../mats/translations";

class HamburgerButton extends Component {

    openSideNav = (event) => {
        this.props.dispatch(toggleSideNav(true));
    }

    
    render() {

        const {lang, user} = this.props;
        let login_text = translations.login[lang];
        if(user !== "N/A") login_text = user;

        return (
            <div style={{display: "flex", alignItems: "center", zIndex: "100"}}>

                <NavLanguageSelector /> 
                <div><Link to={"/"+translations.user_page_url[lang]+"?lang="+lang} id="top-user-button" style={{backgroundImage: 'url("'+process.env.PUBLIC_URL+'/images/user.png")'}}>{login_text}</Link></div>         
                <div>
                    <span onClick={this.openSideNav} style={{cursor: 'pointer'}}>
                        <svg style={{display: 'block', width: '30px', height: '30px'}}>
                        <path d="M5,8 25,8" stroke="#fff" strokeWidth="2" />
                        <path d="M5,15 25,15" stroke="#fff" strokeWidth="2" />
                        <path d="M5,22 25,22" stroke="#fff" strokeWidth="2" />
                        </svg>	
                    </span>        
                </div>
            </div>
        )
  }
}

export default connect()(HamburgerButton);
