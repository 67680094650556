export const RESOURCES_LOADING = 'RESOURCES_LOADING';
export const HOUSES_LOADED = 'HOUSES_LOADED';
export const HOUSE_LOADED = 'HOUSE_LOADED';
export const ERROR_LOADING_RESOURCES = 'ERROR_LOADING_RESOURCES';
export const CHANGE_LANG_SELECTOR_STATUS = 'CHANGE_LANG_SELECTOR_STATUS';
export const CHANGE_SIDE_NAV_STATUS = 'CHANGE_SIDE_NAV_STATUS';
export const CHANGE_LANGUAGE = 'CHANGE_LANGUAGE';
export const REDIRECT_TO = 'REDIRECT_TO';
export const FORM_SELECTED_VALUES = 'FORM_SELECTED_VALUES';
export const SET_PROVINCE = 'SET_PROVINCE';
export const SET_PLACE = 'SET_PLACE';
export const SET_CITY = 'SET_CITY';
export const SET_START = 'SET_START';
export const SET_HOME_START = 'SET_HOME_START';
export const INIT_START = 'INIT_START';
export const HOME_RESOURCE_LOADING = 'HOME_RESOURCE_LOADING';
export const HOME_HOUSES_LOADED = 'HOME_HOUSES_LOADED';
export const SET_HOME_SORT = 'SET_HOME_SORT';
export const SET_USER_EMAIL = 'SET_USER_EMAIL';
export const UPDATE_SUBSCRIBE_PROPERTY = 'UPDATE_SUBSCRIBE_PROPERTY';
export const UPDATE_SUBSCRIBE_PROPERTY_DETAILS = 'UPDATE_SUBSCRIBE_PROPERTY_DETAILS';
export const UPDATE_LOGIN_POPUP = 'UPDATE_LOGIN_POPUP';
export const UPDATE_BACK_URL = 'UPDATE_BACK_URL';
export const RESOURCES_LOADED = 'RESOURCES_LOADED';
export const UPDATE_RELOAD_SAVED = 'UPDATE_RELOAD_SAVED';
export const UPDATE_SUB_MD5 = 'UPDATE_SUB_MD5';
export const UPDATE_MESSAGE_POPUP = 'UPDATE_MESSAGE_POPUP';


export function unsaveSearch(sub_md5, sub_email, handleResponse = 100){
    return function(dispatch){
        let theURL = "https://m.okbulgaria.com/serve/unsubscribe_search.php";
        let fetchOptions = {
            method: 'POST', 
            body: JSON.stringify({
                sub_md5: sub_md5,
                sub_email: sub_email
            }), 
            headers:{ 'Accept': 'application/json', 'Content-Type': 'application/json' } 
        }

        //reload saved list
        if(handleResponse === 100) dispatch({type: UPDATE_RELOAD_SAVED, payload: false});

        fetch(theURL, fetchOptions)
        .then(response => response.json())
        .then(data => {
            console.log(data.result);
            if(data.result === "OK"){
                //reload saved list
                if(handleResponse === 100) dispatch({type: UPDATE_RELOAD_SAVED, payload: true});
                //reload heart status of search results
                else if(handleResponse === -1) dispatch({type: UPDATE_SUB_MD5, payload: ""});
            }
         })
        .catch(error => {
            dispatch({type: ERROR_LOADING_RESOURCES, payload: error});
        })
    }
}

export function saveSearch(sub_email, sub_sl_id, lang, handleResponse = 100){
    console.log(sub_email, sub_sl_id, lang);
    return function(dispatch){
        let theURL = "https://m.okbulgaria.com/serve/subscribe_search.php";
        //let theURL = "https://m.okbulgaria.com/serve/test_subscribe_search.php";
        let fetchOptions = {
            method: 'POST', 
            body: JSON.stringify({
                sub_sl_id: sub_sl_id,
                sub_email: sub_email,
                sub_lang: lang,
            }), 
            headers:{ 'Accept': 'application/json', 'Content-Type': 'application/json' } 
        }

        fetch(theURL, fetchOptions)
        .then(response => response.json())
        .then(data => {
            console.log(data.sub_md5);
            if(data.result === "OK"){
                //reload heart status of search results
                if(handleResponse === -1) dispatch({type: UPDATE_SUB_MD5, payload: data.sub_md5});
            }
            else{
                dispatch(updateMessagePopup("limit_reached"));
            }
         })
        .catch(error => {
            dispatch({type: ERROR_LOADING_RESOURCES, payload: error});
        })
    }
}

export function updateSaveProperty(saved, sub_ref_id, sub_pid, sub_price, sub_lang, sub_email, fromDetails = 0){
    return function(dispatch){
        //console.log(saved, sub_ref_id, sub_pid, sub_price, sub_lang, sub_email);
        let theURL = "https://m.okbulgaria.com/serve/update_subscribe.php";
        let fetchOptions = {
            method: 'POST', 
            body: JSON.stringify({
                saved: saved,
                sub_ref_id: sub_ref_id,
                sub_pid: sub_pid,
                sub_price: sub_price,
                sub_lang: sub_lang,
                sub_email: sub_email,
            }), 
            headers:{ 'Accept': 'application/json', 'Content-Type': 'application/json' } 
        }
        fetch(theURL, fetchOptions)
        .then(response => response.json())
        .then(data => {
            if(Number(data.result)===1){
                if(fromDetails === 0)
                dispatch({
                    type: UPDATE_SUBSCRIBE_PROPERTY, 
                    payload: {
                        data
                    }
                });
                else
                dispatch({
                    type: UPDATE_SUBSCRIBE_PROPERTY_DETAILS, 
                    payload: {
                        data
                    }
                });
            }
            else if(Number(data.result)===0){
                dispatch(updateLoginPopup("1"));
            }
            else if(Number(data.result)===2){
                dispatch(updateMessagePopup("limit_reached"));
            }
         })
        .catch(error => {
            dispatch({type: ERROR_LOADING_RESOURCES, payload: error});
        })
    }
}

export function loadHomeHouses(lang, housesIndex, start, limit_count, sort_by, userEmail = "N/A"){
    return function(dispatch){
        let theURLs = ['https://m.okbulgaria.com/serve/get_data_heart.php?lang='+lang+'&ptype_id=0&plocation_ids=0&minPrice=0&maxPrice=500000&date_added=240&pprovince_id=0&pplace_id=0&distance_city=10&city_id=0&sort_by='+sort_by+'&start='+start+'&limit_count='+limit_count, 'https://m.okbulgaria.com/serve/get_data_heart.php?lang='+lang+'&ptype_id=0&plocation_ids=0&minPrice=0&maxPrice=5000&date_added=0&pprovince_id=0&pplace_id=0&distance_city=10&city_id=0&sort_by='+sort_by+'&start='+start+'&limit_count='+limit_count, 'https://m.okbulgaria.com/serve/get_data_heart.php?lang='+lang+'&ptype_id=15&plocation_ids=0&minPrice=0&maxPrice=500000&date_added=0&pprovince_id=0&pplace_id=0&distance_city=10&city_id=0&sort_by='+sort_by+'&start='+start+'&limit_count='+limit_count, 'https://m.okbulgaria.com/serve/get_saved_properties.php?lang='+lang+'&sort_by='+sort_by+'&start='+start+'&limit_count='+limit_count, 'https://m.okbulgaria.com/serve/get_trending.php?lang='+lang+'&sort_by='+sort_by+'&start='+start+'&limit_count='+limit_count];

        let theURL = theURLs[housesIndex];
        //console.log("SORT BY", sort_by, theURL);
        let fetchOptions = {};
        if(userEmail !== "N/A"){
            //userEmail = '2546065@gmail.com';
            fetchOptions = { 
                method: 'POST', 
                body: JSON.stringify({
                    sub_email: userEmail
                }), 
                headers:{ 'Accept': 'application/json', 'Content-Type': 'application/json' } 
            }
        }
        dispatch({
            type: HOME_RESOURCE_LOADING,
            payload: {
                lang,
                housesIndex
            }
        });
        fetch(theURL, fetchOptions)
        .then(response => response.json())
        .then(data => {            
            dispatch({
                type: HOME_HOUSES_LOADED, 
                payload: {
                    data,
                    housesIndex
                }
            });
         })
        .catch(error => {
            dispatch({type: ERROR_LOADING_RESOURCES, payload: error});
        })
        

    }
}

export function loadHouses(lang, ptype_id, plocation_ids, minPrice, maxPrice, date_added, pprovince_id, pplace_id, distance_city, city_id, sort_by, start, limit_count, userEmail = "N/A"){
    return function(dispatch){
        let theURL = 'https://m.okbulgaria.com/serve/get_data_heart.php?log=1&lang='+lang+'&ptype_id='+ptype_id+'&plocation_ids='+plocation_ids+'&minPrice='+minPrice+'&maxPrice='+maxPrice+'&date_added='+date_added+'&pprovince_id='+pprovince_id+'&pplace_id='+pplace_id+'&distance_city='+distance_city+'&city_id='+city_id+'&sort_by='+sort_by+'&start='+start+'&limit_count='+limit_count;
        //console.log(theURL);
        let fetchOptions = {};
        if(userEmail !== "N/A"){
            //userEmail = '2546065@gmail.com';
            fetchOptions = { 
                method: 'POST', 
                body: JSON.stringify({
                    sub_email: userEmail
                }), 
                headers:{ 'Accept': 'application/json', 'Content-Type': 'application/json' }
            }
        }        
        dispatch({
            type: FORM_SELECTED_VALUES, 
            payload: {
                ptype_id, 
                plocation_ids, 
                minPrice, 
                maxPrice,
                date_added,
                pprovince_id,
                pplace_id,
                distance_city,
                city_id,
                sort_by
            }
        });
        dispatch({type: RESOURCES_LOADING, payload: lang});
        fetch(theURL, fetchOptions)
        .then(response => response.json())
        .then(data => {
            dispatch({type: HOUSES_LOADED, payload: data});
         })
        .catch(error => {
            dispatch({type: ERROR_LOADING_RESOURCES, payload: error});
        })
        
    }
}

export function loadHouse(lang, refID, userEmail = "N/A"){
    return function(dispatch){
        dispatch({type: RESOURCES_LOADING, payload: lang});

        let fetchOptions = {};
        if(userEmail !== "N/A"){
            //userEmail = '2546065@gmail.com';
            fetchOptions = {
                method: 'POST', 
                body: JSON.stringify({
                    sub_email: userEmail
                }), 
                headers:{ 'Accept': 'application/json', 'Content-Type': 'application/json' } 
            }
        }
        let theURL = 'https://m.okbulgaria.com/serve/get_property.php?lang='+lang+'&ref_id='+refID;
        fetch(theURL, fetchOptions)
        .then(response => response.json())
        .then(data => {
            dispatch({type: HOUSE_LOADED, payload: data});
         })
        .catch(error => {
            dispatch({type: ERROR_LOADING_RESOURCES, payload: error});
        })
        
    }

}

export function changeLanguage(lang){
    return { type: CHANGE_LANGUAGE, payload: lang};
}

export function toggleLangSelector(openedStatus){
    return { type: CHANGE_LANG_SELECTOR_STATUS, payload: openedStatus};
}

export function toggleSideNav(openedStatus){
    return { type: CHANGE_SIDE_NAV_STATUS, payload: openedStatus};
}

export function redirectTo(toURL){
    return { type: REDIRECT_TO, payload: toURL};
}

export function setProvince(province){
    return { type: SET_PROVINCE, payload: province}
}
export function setPlace(place){
    return { type: SET_PLACE, payload: place}
}
export function setCity(city){
    return { type: SET_CITY, payload: city}
}
export function setUserEmail(userEmail, userName){
    return { type: SET_USER_EMAIL, payload: {userEmail: userEmail, userName: userName}}
}
export function handleNextPrevProperties(nextPrev){
    return { type: SET_START, payload: nextPrev}
}
export function handleHomeNextPrevProperties(nextPrev, housesIndex){
    return { type: SET_HOME_START, payload: {nextPrev, housesIndex}}
}
export function initStart(startPos){
    return { type: INIT_START, payload: startPos}
}
export function changeHomeSortBy(sortBy, housesIndex){
    return { type: SET_HOME_SORT, payload: {sortBy, housesIndex}}
}
export function updateLoginPopup(showLoginPopup){
    return { type: UPDATE_LOGIN_POPUP, payload: showLoginPopup}
}
export function updateBackURL(backURL){
    return { type: UPDATE_BACK_URL, payload: backURL}
}
export function updateMessagePopup(message){
    return { type: UPDATE_MESSAGE_POPUP, payload: message}
}